.postedMementos.sortBtn {
    background-color: #04A5C0;
    border-radius: 10px;
}

.postedMementos.sortBtn span {
    color: white;
}

.postedMementos.sortBtn i {
    color: white !important;
}

.mementosCnt {
    padding: 10px 0;
    text-align: center;
    max-height: 60rem;
    overflow: scroll;
}

.mementosCnt .memento {
    background-color: white;
    display: inline-block;
    border-radius: 5px;
    margin: 10px;
    padding: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}

.mementosCnt .memento .grid-row-img {
    width: 150px;
    height: 100px;
    border-radius: 5px;
    margin-top: 10px;
}

.mementosCnt .memento .moActions {
    margin-top: 0.5rem;
}

.mementosCnt .memento .moActions i {
    border-radius: 5px;
    background-color: #04A5C0;
    margin: 5px;
    font-size: 22px;
    vertical-align: bottom;
    padding: 5px;
    color: white;
    transition: 0.3s;
    display: inline-block;
}

.mementosCnt .memento .moActions .ti-infinite {
    transform: rotate(90deg);
    color: #323336;
}

.mementosCnt .memento .moActions .ti-infinite:hover {
    color: white;
}

.mementosCnt .memento .moActions i:hover {
    color: #323336;
    cursor: pointer;
}

.mementosCnt .memento .moActions .active {
    color: white !important;
    transform: rotate(0deg);
}

.mementosCnt .memento .moExpiry {
    background-color: #04A5C0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin: -5px;
}

.mementosCnt .memento .moExpiry p {
    margin-bottom: 0;
    color: white;
    font-weight: bold;
}

.mementosCnt .memento .moExpiry .expired {
    color: black;
}