.promosCnt .promo {
    border: 1px solid #B5F767;
    box-shadow: 0px 0px 5px 2px #5b7377;
    margin: 15px 0;
    padding: 5px 10px;
    border-radius: 10px;
}

.promo p {
    margin: 0;
}

.promo.help {
    border: none;
    box-shadow: none;
    padding-left: 0;
}

.promo.help p {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
}

.promo.help i {
    vertical-align: middle;
    font-size: 16px;
}