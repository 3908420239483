
.thank-you.container {
    display: flex;
    justify-content: center;
    height: 100%;
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.section {
    margin-top: 2rem;
}

.next-steps .step {
    background-color: #b5f767;
    margin: 1rem;
    padding: 0.5rem;
    border-radius: 10rem;
    font-size: 16px;
    display: block;
}

.feedBack .feedBackButton {
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #b5f767;
    border: 1px solid #b5f767;
    font-size: 15px;
    padding: 0.5rem;
    margin-top: 1rem;
    border-radius: 10rem;
    transition: 0.3s;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.feedBack .feedBackButton:hover {
    cursor: pointer;
}