.family-members-section {
    margin: 20px 0;
}

.section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    padding: 20px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.section-title-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
}

.section-title-wrapper i {
    font-size: 24px;
    color: #088dcd;
}

.section-title {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    color: #2b2b2b;
}

.add-member-controls {
    display: flex;
    align-items: center;
    gap: 15px;
    position: relative;
}

.add-member-controls select {
    min-width: 250px;
    height: 45px;
    border: 1px solid #e4e4e4;
    border-radius: 8px;
    padding: 0 20px;
    background: #f8f9fa;
    color: #333;
    font-size: 14px;
    transition: all 0.2s ease;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 15px;
    padding-right: 45px;
}

.add-member-controls select:focus {
    border-color: #088dcd;
    outline: none;
    background-color: #fff;
    box-shadow: 0 0 0 3px rgba(8, 141, 205, 0.1);
}

.add-member-controls select:hover {
    background-color: #fff;
    border-color: #088dcd;
}

.add-member-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    height: 45px;
    background: #088dcd;
    border: none;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.2s ease;
    white-space: nowrap;
}

.add-member-btn i {
    margin-right: 8px;
    font-size: 16px;
}

.add-member-btn:hover:not(:disabled) {
    background: #0779b3;
    transform: translateY(-1px);
}

.add-member-btn:disabled {
    background: #e8e8e8;
    color: #999;
    cursor: not-allowed;
    transform: none;
}

.add-member-controls select:invalid,
.add-member-controls select option[value=""] {
    color: #666;
}

.add-member-controls select option {
    color: #333;
    padding: 10px;
    background: #fff;
}

.add-member-controls {
    display: flex;
    align-items: center;
    gap: 10px;
}

.add-member-btn {
    display: flex;
    align-items: center;
    padding: 8px 20px;
    background: #088dcd;
    border: none;
    border-radius: 30px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.3s ease;
    box-shadow: 0 2px 6px rgba(8, 141, 205, 0.2);
}

.add-member-btn i {
    margin-right: 8px;
    font-size: 16px;
}

.add-member-btn:hover:not(:disabled) {
    background: #0779b3;
    box-shadow: 0 4px 12px rgba(8, 141, 205, 0.3);
    transform: translateY(-1px);
}

.add-member-btn:disabled {
    background: #e0e0e0;
    cursor: not-allowed;
    box-shadow: none;
    opacity: 0.7;
}

/* Add a nice transition when select has no value */
.add-member-controls select:invalid,
.add-member-controls select option[value=""] {
    color: #999;
}

/* Style the placeholder option */
.add-member-controls select option {
    color: #333;
    padding: 10px;
}

.members-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.member-group {
    background: #f9f9f9;
    border-radius: 4px;
    padding: 15px;
}

.group-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.group-header i {
    margin-right: 10px;
    color: #088dcd;
}

.group-header h6 {
    margin: 0;
    font-weight: 600;
}

.member-fields {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.family-member-field {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    position: relative;
    padding: 15px;
    background: white;
    border-radius: 4px;
    border: 1px solid #eee;
}

.family-member-field>div {
    flex: 1;
}

.remove-member-btn {
    background: none;
    border: none;
    color: #f44336;
    cursor: pointer;
    padding: 5px;
    position: absolute;
    right: 10px;
    top: 10px;
}

.remove-member-btn:hover {
    color: #d32f2f;
}

.member-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: #f5f5f5;
    border-radius: 50%;
    color: #088dcd;
}

.icon-male {
    color: #2196f3;
}

.icon-female {
    color: #e91e63;
}

/* Styling for the relation select dropdown */
.add-member-controls {
    display: flex;
    align-items: center;
    gap: 15px;
}

.add-member-controls select {
    min-width: 200px;
    height: 42px;
    border: 2px solid #e4e4e4;
    border-radius: 30px;
    padding: 0 20px;
    background: white;
    color: #333;
    font-size: 14px;
    transition: all 0.3s ease;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 15px;
    padding-right: 45px;
}

.add-member-controls select:focus {
    border-color: #088dcd;
    outline: none;
    box-shadow: 0 2px 8px rgba(8, 141, 205, 0.15);
}

.add-member-controls select:hover {
    border-color: #088dcd;
}

/* Child-specific styles */
.children-fields {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.child-field {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    background: white;
    border-radius: 4px;
    padding: 15px;
    border: 1px solid #eee;
    position: relative;
}

.child-field>div {
    flex: 1;
}

/* .family-members-section {
    background: #fff;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 2rem;
}

.section-header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #f0f2f5;
}

.section-header i {
    font-size: 1.5rem;
    color: #666;
    margin-right: 0.75rem;
}

.section-title {
    margin: 0;
    color: #333;
    font-size: 1.25rem;
    font-weight: 600;
}

.member-group {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
}

.group-header {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
}

.group-header i {
    font-size: 1.25rem;
    color: #666;
    margin-right: 0.75rem;
}

.group-header h6 {
    margin: 0;
    color: #444;
    font-size: 1.1rem;
    font-weight: 600;
}

.member-fields {
    display: grid;
    gap: 1.5rem;
}

.family-member-field {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    gap: 1rem;
    align-items: start;
    padding: 1rem;
    background: white;
    border-radius: 6px;
    border: 1px solid #e9ecef;
}

.member-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #f8f9fa;
}

.icon-male {
    color: #4299e1;
}

.icon-female {
    color: #ed64a6;
}

.icon-default {
    color: #718096;
}

.children-group {
    background: #f8f9fa;
}

.group-header .add-child-btn {
    margin-left: auto;
    padding: 0.5rem 1rem;
    background: #4299e1;
    color: white;
    border: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: all 0.2s;
}

.group-header .add-child-btn:hover {
    background: #3182ce;
}

.children-fields {
    display: grid;
    gap: 1rem;
}

.child-field {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    gap: 1rem;
    align-items: start;
    padding: 1rem;
    background: white;
    border-radius: 6px;
    border: 1px solid #e9ecef;
}

.remove-child-btn {
    width: 32px;
    height: 32px;
    border: none;
    border-radius: 50%;
    background: #fee2e2;
    color: #ef4444;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;
}

.remove-child-btn:hover {
    background: #fecaca;
}

@media (max-width: 768px) {

    .family-member-field,
    .child-field {
        grid-template-columns: 1fr;
    }

    .member-icon {
        display: none;
    }
} */