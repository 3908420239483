/* .famTreeModal {
    width: 90%;
    max-width: 1200px;
    height: 90vh;
    padding: 0;
    border-radius: 12px;
    border: none;
    background: #fff;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.famTreeModal::backdrop {
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(4px);
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 2rem;
    border-bottom: 1px solid #eef2f6;
    background: #f8fafc;
    border-radius: 12px 12px 0 0;
}

.modal-title {
    margin: 0;
    font-size: 1.75rem;
    color: #1e293b;
    font-weight: 600;
}

.close-button {
    background: none;
    border: none;
    cursor: pointer;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #64748b;
    transition: all 0.2s ease;
}

.close-button:hover {
    background: #f1f5f9;
    color: #334155;
}

.close-button i {
    font-size: 1.25rem;
}

.modal-content {
    padding: 2rem;
    overflow-y: auto;
    max-height: calc(90vh - 80px);
}

.family-tree-container {
    max-width: 1100px;
    margin: 0 auto;
}

.tree-layout {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.generation-section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
}

.generation-section::after {
    content: '';
    position: absolute;
    bottom: -1.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 1.5rem;
    background: #e2e8f0;
}

.generation-section:last-child::after {
    display: none;
}

.section-header {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1.5rem;
}

.section-icon {
    color: #64748b;
    font-size: 1.25rem;
}

.section-title {
    margin: 0;
    color: #334155;
    font-size: 1.25rem;
    font-weight: 600;
}

.members-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 1.5rem;
}

.family-member-card {
    background: #f8fafc;
    border-radius: 12px;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    transition: all 0.2s ease;
    border: 1px solid #e2e8f0;
}

.family-member-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    border-color: #cbd5e1;
}

.member-icon {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e2e8f0;
    flex-shrink: 0;
}

.member-icon i {
    font-size: 1.5rem;
}

.icon-male {
    color: #3b82f6;
}

.icon-female {
    color: #ec4899;
}

.member-info {
    flex: 1;
}

.member-info h4 {
    margin: 0;
    color: #1e293b;
    font-size: 1.125rem;
    font-weight: 600;
}

.relationship {
    color: #64748b;
    font-size: 0.875rem;
    margin: 0.25rem 0 0 0;
}

.parents-group,
.parents-siblings {
    margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
    .famTreeModal {
        width: 100%;
        height: 100vh;
        max-width: none;
        margin: 0;
        border-radius: 0;
    }

    .modal-header {
        padding: 1rem 1.5rem;
    }

    .modal-content {
        padding: 1.5rem;
    }

    .members-grid {
        grid-template-columns: 1fr;
    }
}


.modal-content::-webkit-scrollbar {
    width: 8px;
}

.modal-content::-webkit-scrollbar-track {
    background: #f1f5f9;
}

.modal-content::-webkit-scrollbar-thumb {
    background: #cbd5e1;
    border-radius: 4px;
}

.modal-content::-webkit-scrollbar-thumb:hover {
    background: #94a3b8;
} */
.famTreeModal {
    width: 90vw;
    max-width: 1200px;
    max-height: 90vh;
    border: none;
    border-radius: 12px;
    padding: 0;
    background: #fff;
}

.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #eee;
}

.modal-title {
    margin: 0;
    font-size: 24px;
    color: #333;
}

.close-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #666;
    padding: 5px;
}

.close-button:hover {
    color: #333;
}

.modal-content {
    padding: 20px;
    overflow-y: auto;
    max-height: calc(90vh - 80px);
}

.family-tree-container {
    width: 100%;
}

.tree-layout {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.generation-section {
    width: 100%;
    padding: 20px;
    background: #f8f9fa;
    border-radius: 12px;
}

/* Specific generation sections */
.great-grandparents {
    background: #f3f4f6;
}

.grandparents {
    background: #f5f6f7;
}

.parents {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.honoree-level {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.relatives-group,
.siblings-spouse-group,
.cousins-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Section styling */
.family-section {
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.section-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.section-icon {
    margin-right: 10px;
    color: #088dcd;
    font-size: 20px;
}

.section-title {
    margin: 0;
    font-size: 18px;
    color: #333;
}

.members-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .parents,
    .honoree-level {
        grid-template-columns: 1fr;
    }

    .members-grid {
        grid-template-columns: 1fr;
    }

    .famTreeModal {
        width: 95vw;
    }
}